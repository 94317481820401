<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/JGGK' }">机构概况</el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <h1 align="center" style="text-align:center;">
        国家级放射影像专业质控中心亚专业指标专家工作组工作章程
      </h1>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        国家级放射影像专业质控中心亚专业指标专家工作组工作章程
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        一、总则
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;为了规范国家级放射影像专业<span>质控中心亚专业指标专家工作组（以下简称</span><span>“工作组”）的工作流程，提高工作效率，确保工作质量，制定本工作章程。</span>
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;工作组是在国家级放射影像专业质控中心的指导下，由相关领域的专家组成的，负责制定和执行亚专业指标的机构。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        二、组织结构
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;<span>工作组设组长</span><span>1名，副组长不超过6名，组员不超过2</span>2名。组长由国家级放射影像专业质控中心指定，副组长和组员由组长提名，报国家级放射影像专业质控中心批准。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;<span>工作组下设工作秘书</span><span>1</span>-2名，负责日常工作。秘书由组长根据工作需要提名，报国家级放射影像专业质控中心批准。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        三、工作职责
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;工作组的主要职责包括：通过循证科学、专家讨论共识、专家经验总结等方式，制定上报亚专业质控指标，确保指标的科学性、合理性和可操作性。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;工作组应定期召开会议，讨论亚专业质控指标的制定和执行情况，提出改进意见和建议。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        3.&nbsp;工作组应积极与其他质控相关组织机构合作，共同推动亚专业质控指标的制定和实施。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        4.&nbsp;工作组接受国家级放射影像专业质控中心的监督和指导，及时定期向国家级放射影像专业质控中心报告工作进展情况。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        四、工作流程
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;工作组制定亚专业质控指标时，应充分征求相关领域的专家意见，确保指标的科学性和合理性。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;制定好的亚专业质控指标应报国家级放射影像专业质控中心审批，经批准后方可执行。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        3.&nbsp;执行过程中，工作组应定期对亚专业质控指标进行评估和调整，确保其适应临床实践的发展。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        4.&nbsp;评估和调整结果应及时报国家级放射影像专业质控中心备案。
      </p>
      <p class="MsoNormal" style="text-indent:24.0000pt;">
        五、附则
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        1.&nbsp;本工作章程自发布之日起施行。如有未尽事宜，由国家级放射影像专业质控中心解释并制定补充规定。
      </p>
      <p class="MsoNormal" style="margin-left:22.0000pt;text-indent:-22.0000pt;">
        2.&nbsp;本工作章程的修改和废止，须经工作组全体成员一致同意，并报国家级放射影像专业质控中心批准。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name:"ZJZGZZC",
}
</script>

<style scoped>
.wrap {
  width: 70%;
  background: #fff;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}

h1 {
  font-size: 22px;
  margin-bottom: 40px;
}
</style>